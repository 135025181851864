import React, { useEffect } from 'react'
import { loadIpInfo } from '../helpers/misc'
import { segmentAnonymousId } from '../helpers/tracking'

// const FACEBOOK_PIXEL_ID = process.env.NEXT_PUBLIC_FACEBOOK_PIXEL_ID
// const GORGIAS_CHAT_ID = process.env.NEXT_PUBLIC_GORGIAS_CHAT_ID

const externalScripts = [
  // segment
  {
    id: 'segment',
    innerHTML: `
      !(function () {
        var analytics = (window.analytics = window.analytics || [])
        if (!analytics.initialize)
          if (analytics.invoked)
            window.console &&
              console.error &&
              console.error('Segment snippet included twice.')
          else {
            analytics.invoked = !0
            analytics.methods = [
              'trackSubmit',
              'trackClick',
              'trackLink',
              'trackForm',
              'pageview',
              'identify',
              'reset',
              'group',
              'track',
              'ready',
              'alias',
              'debug',
              'page',
            'once',
            'off',
            'on',
            'addSourceMiddleware',
            'addIntegrationMiddleware',
            'setAnonymousId',
            'addDestinationMiddleware'
          ]
          analytics.factory = function (e) {
            return function () {
              var t = Array.prototype.slice.call(arguments)
              t.unshift(e)
              analytics.push(t)
              return analytics
            }
          }
          for (var e = 0; e < analytics.methods.length; e++) {
            var key = analytics.methods[e]
            analytics[key] = analytics.factory(key)
          }
          analytics.load = function (key, e) {
            var t = document.createElement('script')
            t.type = 'text/javascript'
            t.async = !0
            t.src =
              'https://cdn.segment.com/analytics.js/v1/' +
              key +
              '/analytics.min.js'
            var n = document.getElementsByTagName('script')[0]
            n.parentNode.insertBefore(t, n)
            analytics._loadOptions = e
          }
          analytics._writeKey = '${process.env.NEXT_PUBLIC_SEGMENT_KEY}'
          analytics.SNIPPET_VERSION = '4.15.3'
          analytics.load('${process.env.NEXT_PUBLIC_SEGMENT_KEY}')
          analytics.page()
        }
      })()
    `
  },
  // crazy egg
  {
    type: 'text/javascript',
    src: '//script.crazyegg.com/pages/scripts/0105/1499.js',
    async: 'async',
    id: 'script-crazy-egg',
    defer: true
  },
  // Branch Web SDK
  {
    innerHTML: `
      // load Branch
      (function(b,r,a,n,c,h,_,s,d,k){if(!b[n]||!b[n]._q){for(;s<_.length;)c(h,_[s++]);d=r.createElement(a);d.async=1;d.src="https://cdn.branch.io/branch-latest.min.js";k=r.getElementsByTagName(a)[0];k.parentNode.insertBefore(d,k);b[n]=h}})(window,document,"script","branch",function(b,r){b[r]=function(){b._q.push([r,arguments])}},{_q:[],_v:1},"addListener applyCode autoAppIndex banner closeBanner closeJourney creditHistory credits data deepview deepviewCta first getCode init link logout redeem referrals removeListener sendSMS setBranchViewData setIdentity track validateCode trackCommerceEvent logEvent disableTracking qrCode".split(" "), 0);
      // init Branch
      branch.init('key_live_bkRC8P6aJ4nA9ZuNpH5EjoaizCm1Gi5C');
    `,
    id: 'branch',
  },
  // share a sale
  {
    type: 'text/javascript',
    src: 'https://www.dwin1.com/46067.js',
    async: 'async',
    id: 'share-a-sale',
    defer: true
  },
  // google tag manager
  {
    id: 'google-tag-manager',
    innerHTML: `
      ;(function (w, d, s, l, i) {
        w[l] = w[l] || []
        w[l].push({ 'gtm.start': new Date().getTime(), event: 'gtm.js' })
        var f = d.getElementsByTagName(s)[0],
          j = d.createElement(s),
          dl = l != 'dataLayer' ? '&l=' + l : ''
        j.async = true
        j.src = 'https://www.googletagmanager.com/gtm.js?id=' + i + dl
        f.parentNode.insertBefore(j, f)
      })(
        window,
        document,
        'script',
        'dataLayer',
        '${process.env.NEXT_PUBLIC_GOOGLE_TAG_MANAGER_ID}'
      )
    `
  },

  // NOTE: disabled for now
  // gorgias chat
  // {
  //   id: 'gorgias-chat-widget-install-v2',
  //   src: `https://config.gorgias.chat/gorgias-chat-bundle-loader.js?applicationId=${GORGIAS_CHAT_ID}`,
  // },

  // {
  //   type: 'text/javascript',
  //   innerHTML: `
  //   const script = document.querySelector('#gorgias-chat-widget-install-v2')
  //   script.addEventListener('load', () => {
  //     if (typeof window.GorgiasChat !== 'undefined') {
  //       GorgiasChat.init().then(function() {
  //         window.GorgiasChat.hidePoweredBy(true)
  //       })
  //     }
  //   })
  //   `
  // },
  
  // facebook
  // () => {
  //   const { default: ReactPixel } = require('react-facebook-pixel');

  //   if (!(ReactPixel && typeof ReactPixel.init === 'function')) {
  //     return
  //   }

  //   ReactPixel.init(FACEBOOK_PIXEL_ID)
  // }
]

let injected = false;
const injectScripts = async () => {
  if (injected) {
    return;
  }
  injected = true

  if (typeof window === 'undefined') {
    return
  }

  // skip analytics for certain ip addresses
  const ipInfo = await loadIpInfo()  
  if (ipInfo?.ip?.startsWith('40.94.')) { // some bot in Azure
    return
  }

  externalScripts.forEach((externalScript) => {
    if (typeof externalScript === 'function') {
      externalScript();
      return
    }

    const scriptExists = document.getElementById(externalScript.id)
    if (scriptExists) {
      return
    }

    const scriptElement = document.createElement('script')
    scriptElement.onerror = err => {
      console.warn('error loading script', err)
    }

    Object.entries(externalScript)
      .forEach(([key, value]) => {
        scriptElement[key] =
          key !== 'innerHTML'
            ? value
            : // wrap in try/catch if
            `
              try {
                ${value}
              } catch (err) {
                console.warn('loading executing script', err)
              }
            `
      });
    document.body.appendChild(scriptElement);
  })

  const analytics = window.analytics
  if (!analytics) {
    console.warn('analytics not loaded')
    return
  }

  // ensure segment anon id is set after segment loads
  segmentAnonymousId()
}

export default React.memo(
  () => {
    useEffect(() => {
      let timeout
      timeout = setTimeout(injectScripts, 10000)
      return () => clearTimeout(timeout)
    }, [])
    return null
  },
  () => true
)
